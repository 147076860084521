import React from "react"
import "../styles/logo.css"
import "../components/Layout"
import Navigation from "../components/Navigation"
import Includes from "../components/Includes"
import Footer from "../components/Footer"
import Header from "../components/Header"

import WhoWeHelp from "../components/Who We Help"







export default function whowehelp() {
  return (<>
            <Header/>
            <Navigation/>
            <br></br>
            <WhoWeHelp/>
            <Includes/>
            <Footer/>

        
  </>)
}